h2 {
  color: #007bc0;
}

h3 {
  color: #007bc0;
}

.sideColumn {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -25%;
}

.whiteSpace {
  height: 20px;
}

.banner {
  height: 100px; /* Full viewport height */
  background-image: url("../../Content/Pictures/Background.jpg"); /* Background image */
  background-size: cover; /* Cover entire container */
  background-attachment: fixed; /* Fixed background */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}
