.main {
  max-width: 55%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 18.5px;
  line-height: 20px;
  color: #777776;
}

.center {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-radius: 100%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.sideColumn1 {
  flex: 1;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.colored {
  background-color: #c6d8e5;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  margin: -10px;
}
.colored1 {
  background-color: #c6d8e5;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

.uncolored {
  padding-right: 20px;
}

@media (max-width: 824px) {
  .sideColumn1 {
    flex: 0;
    display: inline;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .main {
    max-width: 90%;
    margin: 0 auto;
    font-weight: 400;
    font-size: 18.5px;
    line-height: 20px;
    color: #777776;
  }
  .colored1 {
    background-color: #c6d8e5;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
  }
  .colored {
    background-color: #c6d8e5;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
    border-radius: 5px;
    margin: 0;
    margin-top: 20px;
  }
}
