.button {
  position: relative;
  bottom: 50px;
  padding: 9px 20px;
  padding-top: 12px;
  font-size: 14px;
  background-color: #daba8f;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}
