.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-size {
  cursor: pointer;
  transition: width 0.25s ease-in-out, max-height 0.25s ease-in-out;
}

.original-size {
  cursor: pointer;
  transition: width 0.25s ease-in-out, max-height 0.25s ease-in-out;
}
