.responsiveImg {
  max-width: 100%;
  height: auto;
}

.logo {
  margin-left: 20%;
}
.model {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.rightSide {
  padding-top: 10px;
}

t {
  color: #007bc0;
}

.columnPadding {
  padding-left: 8px;
}
