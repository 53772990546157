.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #daba8f;
  color: #777776;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  font-size: 18px;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
}

.navlogo {
  margin-left: 2%;
  margin-top: 5px;
}

.menu-icon {
  display: none;
  cursor: pointer;
  margin-right: 2%;
}

.nav-links {
  display: flex;

  padding-left: 30px;
}

.nav-links a {
  text-decoration: none;
  color: inherit;
  padding: 22.4px 15px;
  cursor: pointer;
}

.nav-links a.active {
  color: white;
  border-bottom: 8px solid white;
}

.spacer {
  height: 75px;
}

@media (max-width: 824px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    background-color: #daba8f;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  }

  .nav-links.active {
    display: flex;
  }

  .menu-icon {
    display: block;
  }
}
