.links {
  text-align: right;
  margin-top: -50px;
}

.footer {
  margin-top: 20px;
  padding: 20px;
  background-color: #777776;
}

.footer a {
  text-decoration: none;
  color: white; /* Change to your desired color */
}

.footer a:hover {
  color: #daba8f; /* Change to a different color if you want a hover effect */
}

.footer-logo {
  width: 80px;
}

.column2 {
  translate: 40px 0px;
}

#one {
  margin-left: 0%;
}

.copyright {
  text-align: center;
  margin-top: 20px;
}

.outLink {
  padding-top: 80px;
}

w {
  color: white;
}

@media (max-width: 824px) {
  .sideColumn#one {
    text-align: center;
    flex: 0;
    display: inline;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 0%;
  }
  .column2 {
    margin-top: 0px;
    translate: 0px 0px;
    margin-right: 0px;
    text-align: center;
  }
  .links {
    margin-top: 20px;
    text-align: center;
  }
}
