.image-overlay-container {
  display: flex;
  justify-content: center;
}

.image-overlay-wrapper {
  position: relative;
  display: inline-block;
}

.image-overlay-wrapper2 {
  position: relative;
  display: inline-block;
}

.image-overlay-image {
  width: 100%;
  max-height: var(--imageHeight, auto);
  display: block;
  object-fit: cover;
}
.image-overlay-image-gallery {
  width: 100%;
  max-height: var(--imageHeight, auto);
  display: block;
  object-fit: contain;
}

.image-overlay-text {
  position: absolute;
  top: var(--top, 70%);
  left: var(--left, 30%);
  transform: var(--transform, translate(-50%, -50%));
  text-align: var(--textAlign, center);
  color: var(--titleColor, #707070);
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.image-overlay-title {
  font-size: var(--titleFontSize, 270%);
}

.image-overlay-subtitle {
  color: var(--subtitleColor, #ffffff);
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 724px) {
  .image-overlay-image {
    width: auto;
    height: var(--maxHeight, "auto");
    display: block;
    object-fit: cover;
  }
  .image-overlay-image-gallery {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .image-overlay-text {
    position: absolute;
    top: var(--top, 70%);
    left: var(--left, 30%);
    transform: var(--transform, translate(-50%, -50%));
    text-align: var(--textAlign, center);
    color: var(--titleColor, #707070);
    width: 20%;
    box-sizing: border-box;
  }
}
