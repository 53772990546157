.image-carousel {
  display: flex;
  align-items: center;
  position: relative;
  display: inline-block;
}

button {
  cursor: pointer;
  font-size: 24px;
  background: transparent;
  border: none;
  position: absolute;

  z-index: 5;
}

button.left {
  left: 10px; /* Position left button 10px from the left edge */
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

button.right {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px; /* Position right button 10px from the right edge */
}

.gallery-container {
  position: relative;
  overflow: hidden;
  max-height: 800px; /* Adjust height as needed */
  transition: transform 1s ease; /* Define the transition effect */
}
